import _Get from 'lodash/get'
import _SortBy from 'lodash/sortBy'
import _IsArray from 'lodash/isArray'
import qs from 'qs'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { Component, Suspense, lazy } from 'react'
import { startCase } from '../../helpers/normalizer'
import InfiniteScroll from 'react-infinite-scroller'
import { goToTop, goToAnchor, configureAnchors } from 'react-scrollable-anchor'

import { search, clearSearch } from '../home/actions'
import { updateFilters, fetchRecipes } from './actions'

import history from '../../history'

import './recipes.scss'

const Filters = lazy(() => import('./components/Filters'))
const Search = lazy(() => import('../../components/search/Search'))

const defaultValues = {
    flavor: null,
    dishes: null,
    courses: null,
}

class AllRecipesPage extends Component {
    componentDidMount() {
        const {
            location: { hash, state = {}, search },
            searchResults,
        } = this.props

        goToTop()

        // Parse query string from URL
        const queryParams = this.queryToFilters(search)

        // Use query params, fall back to state, then to defaults
        this.props.updateFilters({ ...defaultValues, ...state, ...queryParams })

        if (hash) {
            goToAnchor(hash.slice(1), false)
        }

        if (Object.keys(searchResults).length) {
            this.props.clearSearch()
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: { state, pathname, hash, search },
            filters,
            paging,
            history,
        } = this.props
        
        const hasPropsChanged =
            filters.flavor !== prevProps.filters.flavor ||
            filters.dishes !== prevProps.filters.dishes ||
            filters.courses !== prevProps.filters.courses
            
        const hasStateChanged =
            state &&
            (state.flavor !== filters.flavor ||
                state.dishes !== filters.dishes ||
                state.courses !== filters.courses)

        if (hasStateChanged) this.props.updateFilters(state)

        if (hasPropsChanged) {
            this.props.fetchRecipes(filters, { ...paging, page: 1 }, prevProps.sortParameter, true)
            
            const queryStringified = this.filtersToQuery(filters)

            const currentSearchParams = new URLSearchParams(search)
            const newSearchParams = new URLSearchParams(queryStringified)

            // merge the new search params with the current ones (utm tracking)
            newSearchParams.forEach((value, key) => {
                currentSearchParams.set(key, value)
            })

            const mergedSearch = `?${currentSearchParams.toString()}`

            // only update the URL if the query string has changed
            if (search !== mergedSearch) {
                history.push({
                    pathname: pathname,
                    search: mergedSearch,
                    hash,
                })
            }
        }
    }

    // Convert filters to query string
    filtersToQuery(filters) {
        const queryParams = {
            flavor: filters.flavor,
            dishes: filters.dishes,
            courses: filters.courses,
        }

        // filter out empty parameters
        const filteredParams = Object.keys(queryParams)
            .filter((key) => queryParams[key])
            .reduce((obj, key) => {
                obj[key] = queryParams[key]
                return obj
            }, {})

        return qs.stringify(filteredParams, { arrayFormat: 'comma' })
    }

    // Convert query string to filters
    queryToFilters(query) {
        const parsedParams = qs.parse(query, {
            ignoreQueryPrefix: true,
        })

        // split comma-separated values into arrays
        Object.keys(parsedParams).forEach((key) => {
            if (
                typeof parsedParams[key] === 'string' &&
                parsedParams[key].includes(',')
            ) {
                parsedParams[key] = parsedParams[key].split(',')
            } else if (!_IsArray(parsedParams[key])) {
                parsedParams[key] = [parsedParams[key]]
            }
        })

        // remove parameters that are not in defaultValues
        Object.keys(parsedParams).forEach((key) => {
            if (!defaultValues.hasOwnProperty(key)) {
                delete parsedParams[key]
            }
        })

        return parsedParams
    }

    handleSearchChange(searchTerm) {
        searchTerm && this.props.search(searchTerm)
    }

    getTrends() {
        const { trends } = this.props
        return trends.map((item, idx) => {
            return (
                <Link to={item.link} className="block recipe gray" key={idx}>
                    <div className="block-img">
                        <img src={item.image} alt="" />
                    </div>
                    <div className="block-txt">
                        <div className="block-title">
                            <p>{item.title}</p>
                        </div>
                    </div>
                </Link>
            )
        })
    }

    getRecipes() {
        const {
            recipes,
            noResults,
            paging: { loading },
        } = this.props

        if (!loading && noResults) return <p>Sorry, no results were found</p>

        return (
            recipes &&
            recipes.length > 0 &&
            recipes.map((item, idx) => (
                    <Link
                        to={{
                            pathname: `recipes/${item.slug}`,
                            state: { recipeID: item.id },
                        }}
                        className="block recipe"
                        key={idx}
                    >
                        <div className="block-img">
                            <img src={item.thumbnail} alt={`recipe-${idx}`} />
                        </div>
                        <div className="block-txt">
                            <div className="block-title">
                                <p>{startCase(item.title)}</p>
                            </div>
                        </div>
                    </Link>
                ))
        )
    }

    onFilterChange(filterBy, options) {
        let newFilter = {}
        const {
            location: { hash, pathname },
            filters,
        } = this.props

        newFilter[filterBy] = null

        if (options) {
            if (options[filterBy] && options[filterBy].length) {
                newFilter = { flavor: null, dishes: null, courses: null }
                Object.keys(options).forEach((key) => {
                    if (options[key]) {
                        if (Array.isArray(options[key])) {
                            newFilter[key] = options[key].map((o) => o.id || o)
                        } else {
                            newFilter[key] = [options[key]]
                        }
                    }
                })
            }
        }

        this.props.updateFilters(Object.assign({}, filters, newFilter))
        
        // Keep using history.push with state for backward compatibility
        history.push(
            `${pathname}${hash}`,
            Object.assign({}, filters, newFilter)
        )
    }

    onFilterClear() {
        const {
            location: { hash, pathname, search },
        } = this.props
        const filters = { ...defaultValues }
        this.props.updateFilters(filters)
        
        // Clear URL query parameters while maintaining hash
        const currentSearchParams = new URLSearchParams(search)
        
        // Remove filter parameters
        Object.keys(defaultValues).forEach(key => {
            currentSearchParams.delete(key)
        })
        
        const mergedSearch = currentSearchParams.toString() ? 
            `?${currentSearchParams.toString()}` : ''
            
        history.push({
            pathname,
            search: mergedSearch,
            hash,
        }, filters)
    }

    handleInfiniteLoad() {
        const { filters, paging, sortParameter } = this.props
        if (paging.hasNext && !paging.loading) {
            const newPage = Object.assign({}, paging, { page: paging.page + 1 })
            this.props.fetchRecipes(filters, newPage, sortParameter, false)
        }
    }

    render() {
        const {
            location: { hash },
        } = this.props

        if (hash) configureAnchors({ offset: -75 })

        return (
            <div>
                <div className="hero-wrapper recipes">
                    <h1>Recipes and menu trends</h1>
                    <div className="descr">
                        We’ve got some of the best culinary minds in the
                        business pushing the flavor envelope for you every day.
                        Enjoy.
                    </div>
                    <Suspense
                        fallback={<p className="loading">Loading search...</p>}
                    >
                        <Search
                            byType="recipes"
                            className="search-wrapper"
                            results={this.props.searchResults}
                            loading={this.props.loadingResults}
                            placeholder={'What do you want to make today?'}
                            onChange={(e) => {
                                this.handleSearchChange(e.target.value)
                            }}
                        />
                    </Suspense>
                    <a className="button" href="#allRecipes">
                        See all recipes
                    </a>
                    <Link
                        to="/recipes/Thai-Satay-Chicken-Wings"
                        className="recipe-link"
                    >
                        Thai Satay Wings
                    </Link>
                </div>
                <div className="body-wrapper recipes">
                    <h2 className="no-margin">What's trending now on menus</h2>
                    <div className="recipe-list">
                        {this.props.trends.length ? (
                            this.getTrends()
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                </div>
                <div id={'allRecipes'}>
                    <div className="body-wrapper recipes pampas">
                        <h2>All recipes</h2>
                        <Suspense
                            fallback={
                                <p className="loading">Loading search...</p>
                            }
                        >
                            <Search
                                byType="recipes"
                                className="search-wrapper wide"
                                results={this.props.searchResults}
                                loading={this.props.loadingResults}
                                placeholder={
                                    'Recipe, product name/code and more...'
                                }
                                onChange={(e) => {
                                    this.handleSearchChange(e.target.value)
                                }}
                            />
                        </Suspense>
                        <Suspense
                            fallback={
                                <p className="loading">Loading filters...</p>
                            }
                        >
                            <Filters
                                selections={this.props.filters}
                                onFilterClear={() => this.onFilterClear()}
                                dishes={_SortBy(this.props.dishes, 'name')}
                                flavors={_SortBy(this.props.flavors, 'name')}
                                courses={_SortBy(this.props.courses, 'name')}
                                onFilterChange={(f, o) =>
                                    this.onFilterChange(f, o)
                                }
                            />
                        </Suspense>
                        <InfiniteScroll
                            pageStart={0}
                            className="recipe-list mh"
                            loadMore={() => this.handleInfiniteLoad()}
                            hasMore={this.props.paging.hasNext}
                            loader={
                                <div className="loader" key={0}>
                                    Loading ...
                                </div>
                            }
                        >
                            {this.getRecipes()}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sortParameter: _Get(state, 'recipes.sortParameter'),
    recipes: _Get(state, 'recipes.list', []),
    dishes: _Get(state, 'lookup.dishes', []),
    paging: _Get(state, 'recipes.paging', {}),
    courses: _Get(state, 'lookup.courses', []),
    flavors: _Get(state, 'lookup.flavors', []),
    trends: _Get(state, 'lookup.trends', []),
    filters: _Get(state, 'recipes.filters', {}),
    noResults: _Get(state, 'recipes.noResults', {}),
    searchResults: _Get(state, 'home.searchResults'),
    loadingResults: _Get(state, 'home.loadingResults'),
})

export default connect(mapStateToProps, {
    search,
    clearSearch,
    updateFilters,
    fetchRecipes
})(AllRecipesPage)
